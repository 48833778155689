import React, { useState, useEffect } from 'react';
import './Slider.scss';

const Slider = () => {
    const slides = [
        {
            image: `${process.env.PUBLIC_URL}/image1.png`,
            title: 'Was wir für Sie tun',
            text: 'Wir begleiten Sie bei jeder geschäftlichen Investition. Unsere digitalen Tools bieten tagesaktuelle Konditionen, maßgeschneiderte Vertragslösungen und eine professionelle Betreuung – vor, während und nach dem Vertragsabschluss.',
        },
        {
            image: `${process.env.PUBLIC_URL}/image2.png`,
            title: 'Wie wir arbeiten',
            text: 'Wir haben den gesamten Leasing-Prozess vereinfacht: Unsere digitale Lösung erlaubt es Ihnen, jeden Schritt, von der Bonitätsprüfung bis zum Vertragsabschluss, bequem online zu erledigen – alles aus einer Quelle.',
        },
    ];

    const [currentSlide, setCurrentSlide] = useState(0);
    const [fade, setFade] = useState(true); // State for fading effect

    useEffect(() => {
        const interval = setInterval(() => {
            setFade(false); // Trigger fade out
            setTimeout(() => {
                setCurrentSlide((prevSlide) => (prevSlide === slides.length - 1 ? 0 : prevSlide + 1));
                setFade(true); // Trigger fade in
            }, 500); // Duration of fade-out
        }, 20000); // Change slide every 10 seconds

        return () => clearInterval(interval);
    }, [slides.length]);

    return (
        <div className="slider-container">
            <div className={`slider-row ${fade ? 'fade-in' : 'fade-out'}`}>
                <div className="slider-column" /> {/* Empty first column */}
                <div className="slider-column slider-image">
                    <img src={slides[currentSlide].image} alt="Slider Image" />
                </div>
                <div className="slider-column slider-text">
                    <h2>{slides[currentSlide].title}</h2>
                    <p>{slides[currentSlide].text}</p>
                </div>
                <div className="slider-column" /> {/* Empty fourth column */}
            </div>
            <div className="dots">
                {slides.map((_, index) => (
                    <span key={index} className={`dot ${index === currentSlide ? 'active' : ''}`}></span>
                ))}
            </div>
        </div>
    );
};

export default Slider;
