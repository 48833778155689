import React from 'react';
import './App.scss';
import NavBar from './components/NavBar';
import Billionaire from './components/Billionaire';
import Slider from './components/Slider';
import Footer from './components/Footer';

function App() {
    return (
        <div className="App">
            <NavBar />
            <Billionaire />
            <Slider />
            <Footer />
        </div>
    );
}

export default App;
