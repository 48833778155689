import React from 'react';
import './Billionaire.scss';

const Billionaire = () => {
    return (
        <div className="billionaire-section">
            <div className="overlay">
                <form className="overlay-form">
                    <div className="form-group">
                        <label>Hauptkategorie</label>
                        <input type="text" placeholder="Hauptkategorie" />
                    </div>

                    <div className="form-group">
                        <label>Unterkategorie</label>
                        <input type="text" placeholder="Unterkategorie" />
                    </div>

                    <div className="form-group">
                        <label>Anschaffungskosten (netto)</label>
                        <input type="number" placeholder="€10,000.00" />
                    </div>

                    <div className="form-group">
                        <label>Gewünschte Vertragsdauer</label>
                        <input type="number" placeholder="48 months" />
                    </div>

                    <div className="form-group">
                        <label>Gewünschte Anzahlung</label>
                        <input type="text" placeholder="€0,00" />
                    </div>

                    <div className="form-group">
                        <label>Gewünschter Restwert</label>
                        <input type="text" placeholder="€1,500.00" />
                    </div>

                    <div className="form-group">
                        <label>Prozentuale Anzahlung</label>
                        <input type="text" placeholder="0.00%" />
                    </div>

                    <div className="form-group">
                        <label>Kalkulieren</label>
                        <button className="update-btn">Kalkulation Aktualisieren</button>
                    </div>
                </form>
            </div>
            <img
                src={`${process.env.PUBLIC_URL}/billionair.png`}
                alt="Billionaire"
                className="billionaire-image"
            />
        </div>
    );
};

export default Billionaire;
