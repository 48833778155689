import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/Menu';

// Reference the logo from the public folder
const logoPath = process.env.PUBLIC_URL + '/easlease.png';

const NavBar = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    // Handle menu open
    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    // Handle menu close
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="static" color="transparent" elevation={0}>
            <Toolbar style={{ justifyContent: 'space-between' }}>
                {/* Left Side: Logo */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={logoPath} alt="EasLease Logo" style={{ height: '40px', marginRight: '10px' }} />
                    <Typography variant="h6" className="navbar-logo" style={{ flexGrow: 1 }}>
                        Eas<span className="navbar-logo">Lease</span>
                    </Typography>
                </div>

                {/* Center: Menu Items */}
                <div>
                    <Button color="inherit">Startseite</Button>
                    <Button color="inherit">Ihr Anliegen</Button>
                    <Button color="inherit" onClick={handleMenuClick}>
                        Unsere Lösungen
                    </Button>
                    {/* Dropdown menu for 'Unsere Lösungen' */}
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={handleMenuClose} open>Option 1</MenuItem>
                    </Menu>
                    <Button color="inherit">Ihre Projekte</Button>
                    <Button color="inherit">Über uns</Button>
                </div>

                {/* Right Side: Login and Action Button */}
                <div>
                    <Button variant="outlined" color="inherit" className="navbar-button">
                        Ihr Login
                    </Button>
                    <Button variant="contained" style={{ backgroundColor: '#0676ce', color: '#fff' }} className="navbar-button">
                        Anfrage Starten
                    </Button>
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default NavBar;
