import React from 'react';
import './Footer.scss';

const Footer = () => {
    return (
        <div className="footer-container">
            {/* Top CTA Section */}
            <div className="cta-section">
                <div className="cta-card">
                    <p>Sie haben Wünsche oder Fragen? Unsere Kundenberater sprechen gerne persönlich mit Ihnen.</p>
                    <button className="cta-button">Rückruf Vereinbaren</button>
                </div>
                <div className="cta-card">
                    <p>Sie sind bereits EasLease-Kunde und haben Zugangsdaten?</p>
                    <p>Loggen Sie sich bitte hier ein.</p>
                    <button className="cta-button">Anmelden</button>
                </div>
            </div>

            {/* Bottom Information Section */}
            <div className="footer-bottom">
                <div className="footer-links">
                    <a href="#">Kontakt</a>
                    <a href="#">News</a>
                    <a href="#">Lexikon</a>
                    <a href="#">FAQs</a>
                    <a href="#">Presse</a>
                </div>
                <div className="footer-social">
                    <a href="#"><i className="icon-xing"></i></a>
                    <a href="#"><i className="icon-linkedin"></i></a>
                </div>
                <div className="footer-legal">
                    <a href="#">AGB</a>
                    <a href="#">Datenschutz</a>
                    <a href="#">Impressum</a>
                </div>
                <div className="footer-copyright">
                    <p>© 2024 EASLEASE GMBH KÖNIGSALLEE 2 40212 DÜSSELDORF</p>
                </div>
            </div>
        </div>
    );
};

export default Footer;
